<template>
  <list-container-widget
    id="jobs"
    :title="widgetName"
    :app-id="20"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="jobs" />
    </template>

    <!-- Container with data -->
    <div v-if="items && items.length > 0 && canList">
      <div ref="container" class="x-scroll-container w-100" @scroll="onScroll">
        <!-- Items Loop -->
        <b-card
          v-for="(item, index) in items"
          :key="index"
          no-body
          class="x-scroll-element mx-1 mb-3 p-0 card-standard"
          style="height: 300px"
        >
          <b-link :to="canView ? { name: 'job', params: { id: item.key } } : null">
            <b-card-body class="pb-2 d-block">
              <!--    <div class="item-img text-center">
                <b-img
                  :alt="`${item.name}`"
                  fluid
                  :src="getImageSrc(item.bgURL) || jobsPlaceholder"
                  class="card-image-medium"
                />
              </div> -->
              <!-- Job Details -->
              <b-badge v-if="item.statusID" class="float-right mt-50" :variant="getBadgeVariant(item.statusID)">
                {{ getBadgeText(item.statusID) }}
              </b-badge>
              <h4 class="text-ellipsis-lines-2">
                {{ item.title }}
              </h4>
              <b-card-text v-if="item.location" class="text-secondary mb-0">
                {{ item.location.locality }}, {{ item.location.region }}
              </b-card-text>
              <b-card-text class="text-muted text-ellipsis-lines-2">
                {{ item.responsibilities }}
              </b-card-text>
              <!-- Experience -->
              <!--  <b-card-text v-if="item.minYearsExp && item.maxYearsExp" class="headline text-muted">
                {{ item.minYearsExp }} - {{ item.maxYearsExp }} years experience
              </b-card-text>
              <b-card-text v-else-if="item.minYearsExp && !item.maxYearsExp" class="headline text-muted">
                Minimum {{ item.minYearsExp }} years experience
              </b-card-text>
              <b-card-text v-else-if="!item.minYearsExp && item.maxYearsExp" class="headline text-muted">
                {{ item.maxYearsExp }} years experience
              </b-card-text>
              <b-card-text v-else class="headline text-muted">
                <b-dd-divider></b-dd-divider>
              </b-card-text> -->
              <!-- Salary -->
              <b-card-text v-if="item.minBruttoSalary && item.maxBruttoSalary" class="headline text-muted">
                {{ $t('jobs.detail.salary') }}: {{ parseFloat(item.minBruttoSalary).toLocaleString(currentLocale, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 3,
                }) }} - {{ parseFloat(item.maxBruttoSalary).toLocaleString(currentLocale, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 3,
                }) }} {{ item.currency }}
              </b-card-text>
              <b-card-text v-else-if="item.minBruttoSalary && !item.maxBruttoSalary" class="headline text-muted">
                {{ $t('jobs.detail.salary') }}: {{ parseFloat(item.minBruttoSalary).toLocaleString(currentLocale, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 3,
                }) }} {{ item.currency }}
              </b-card-text>
              <b-card-text v-else-if="!item.minBruttoSalary && item.maxBruttoSalary" class="headline text-muted">
                {{ $t('jobs.detail.salary') }}: {{ parseFloat(item.maxBruttoSalary).toLocaleString(currentLocale, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 3,
                }) }} {{ item.currency }}
              </b-card-text>
              <b-card-text v-else class="headline text-muted text-ellipsis-lines-1">
                <span />
              </b-card-text>
              <div style="position: fixed; bottom: 20px">
                <span class="text-muted">{{  new Date(item.updatedAt).toLocaleString('en-GB').substring(0, 10)}}</span>
                <!-- Current community -->
                <b-card-text class="d-flex  align-items-center w-100 mt-1 text-secondary">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      size="26"
                      :src="getAvatar(collective.logoURL)"
                      :alt="spaceNameTranslated"
                      class="user-avatar mr-1 float-right"
                    />
                    <span class="font-small-4"> 
                      {{ spaceNameTranslated }} 
                    </span>
                  </div>
                </b-card-text>
              </div>
            </b-card-body>
          </b-link>
        </b-card>
        <!-- Buttons prev and next -->
        <button class="link-previous btn btn-icon btn-primary btn-previous" @click="scrollOnePage(-1)">
          <b-spinner small class="spinner" />
        </button>
        <button
          class="link-next btn btn-icon btn-primary btn-next"
          :class="{ 'btn-next--loading': isLoadingNextPage }"
          @click="scrollOnePage(+1)"
        >
          <b-spinner small class="spinner" />
        </button>
      </div>
    </div>

    <!-- Loading -->
    <div v-else-if="isLoading">
      <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
    </div>
    <b-row v-else-if="!canList" class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="jobsPlaceholder">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t('no-permisions.message') }}
        </p>
      </b-col>
    </b-row>

    <!-- Container without data: Placeholder -->
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="jobsPlaceholder">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t('available.message', { itemName: widgetName }) }}
        </p>
      </b-col>
    </b-row>

    <description-addon v-model="isAppInfoModalVisible" :app-id="3" />
  </list-container-widget>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import DescriptionAddon from '@/@core/widgets/DescriptionAddon.vue';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { required } from '@validations';
import JobsPlaceholder from '@/assets/images/placeholders/light/jobs.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
// import JobCreateForm from '../components/JobCreateForm.vue';

export default {
  name: 'JobsListWidget',
  components: {
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
    DescriptionAddon,
    WidgetActions,
    ListContainerWidget,
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      jobInput: {},
      formError: null,
      imageSrc: null,
      isAppInfoModalVisible: false,
      searchInput: '',
      results: [],
      required,
      isContactModalVisible: false,

      // Filters data
      areFiltersVisible: false,
      nextFilter: null,
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      selectedCustomFieldsForFilter: null,
      customFieldToSend: {},
      showfilter: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    itemsData() {
      return this.$store.getters.jobs;
    },
    items() {
      const itemsToIterate = this.searchInput ? this.results : this.itemsData.unpaginated;
      return itemsToIterate;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    jobsPlaceholder() {
      return JobsPlaceholder;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions('create', 'job', this.loggedMemberRoles, this.collective);
    },
    canList() {
      return checkPermissions('view', 'job', this.loggedMemberRoles, this.collective);
    },
    canView() {
      return checkPermissions('view', 'job', this.loggedMemberRoles, this.collective);
    },
    spaceNameTranslated() {
      return this.translateTranslationTable(this.$store.state.locale.currentLocale, this.collective?.name)
    }
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getAvatar(url) {
      return getImageResource(url);
    },

    async fetchData(force = false) {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'jobs',
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          count: 16,
          orderByDate: -1,
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd = event.srcElement.scrollWidth - event.srcElement.scrollLeft - event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      } else {
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      const searchTermToLower = searchTerm.toLowerCase();
      this.results = this.itemsData.unpaginated.filter((item) => {
        const itemNameStr = JSON.stringify(item.name).toLowerCase();
        if(itemNameStr.includes(searchTermToLower)){
          return item;
        }
      });
    },
    getBadgeText(status) {
      if (!status || status === 42678) {
        return 'Abierto';
      }
      if (!status || status === 42681) {
        return 'Abierto';
      }
      if (status == 42659 || status === 42736) {
        return 'Resuelto';
      }
    },
    getBadgeVariant(status) {
      if (!status || status === 42658) {
        return 'warning';
      }
      if (status == 42659 || status === 42678) {
        return 'success';
      }
    },
    translateTranslationTable,
  },
};
</script>

<style></style>
